import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Nav from "../components/Nav"
import MobileMenu from "../components/MobileMenu"

import Banner from "../components/Banner"
import PageTitle from "../components/ui/PageTitle"
import RenderBlock from "../components/RenderBlock"

const StyledTextContent = styled.div`
  .aftercare-block {
    h3 {
      margin: 0 0 15px 0;
      padding-top: 15px;
      color: var(--red);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.2em;
    }
    p {
      margin-bottom: 15px;
    }
  }
  &.no-bottom {
    margin-bottom: 40px;
  }
`
const StyledTel = styled.a`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .tel-image {
    height: 20px;
    min-width: 20px;
    margin-right: 10px;
  }
  span {
    font-weight: normal;
  }
`

export default function AftercarePage({ data }) {
  const details = data.details,
    aftercare = data.aftercare

  return (
    <div className="page">
      <Meta title="Aftercare" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <PageTitle title={aftercare.pageTitle} />
          <Banner
            data={aftercare.bannerText}
            image={aftercare.bannerBackground}
          />
          <StyledTextContent
            className={aftercare.ctaEnabled ? "" : "no-bottom"}
          >
            <RenderBlock
              block={aftercare.textContent}
              className={"aftercare-block"}
            />
          </StyledTextContent>
          {aftercare.ctaEnabled && (
            <div style={{ margin: "40px 0" }}>
              <strong>{aftercare.ctaMessage}</strong>
              <StyledTel href={`tel:${details.phone?.replace(" ", "")}`}>
                {details.contactIcon && (
                  <Image
                    title={"Call us now"}
                    alt={"dark-spectrum-contact-number"}
                    {...details.contactIcon}
                    className="tel-image"
                  />
                )}
                {<span>{`+44 (0)${details.phone}`}</span>}
              </StyledTel>
            </div>
          )}
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </div>
  )
}

export const siteDetails = graphql`
  query {
    details: sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      copyrightMessage
      agencyName
      agencyLink
    }
    aftercare: sanityAftercare {
      pageTitle
      ctaEnabled
      ctaMessage
      bannerBackground {
        asset {
          url
          id
          publicUrl
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
      bannerText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
      textContent {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
    }
  }
`
